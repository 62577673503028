import { AdvertenciaIcon, CheckTrackingIcon, RelojIcon } from '@saesa/ui-kit-front';
import { TipoColoresEnum, TipoEnviadaPorEmail, TipoEtapaTrackingEnum, TipoLargoLineaEnum, TipoPagoBoleta } from 'config';
import { addDays, endOfDay } from 'date-fns';
import { ColorModel } from 'models';
import { FacturacionResponseModel, TrackingEstadosPorIdResponseModel } from 'services/models';
import { esDatoValido, formatoFecha } from './trackingUtils';

/**
 * Función encargada de retornar el color de la línea
 * @param estado estado de la respuesta del servicio de facturacion
 * @returns retorna el color
 */
export const obtenerColorLinea = (estado: TipoPagoBoleta | null) => {
  switch (estado) {
    case undefined:
    case null:
    case TipoPagoBoleta.PAGADO:
      return TipoColoresEnum.VERDE_CLARO;
    case TipoPagoBoleta.PENDIENTE_PAGO:
      return TipoColoresEnum.VERDE_CLARO;
    case TipoPagoBoleta.VENCIDO:
      return TipoColoresEnum.ROJO;
    default:
      return TipoColoresEnum.GRIS_L63;
  }
};

/**
 * Función encargada de retornar el color de la etapa
 * @param estado estados de tracking
 * @returns retorna el color
 */
export const obtenerEstadoTomaLectura = (listadoEstados: any[]): ColorModel['color'] => {
  let color: ColorModel['color'] = TipoColoresEnum.GRIS_L63;
  if (esDatoValido(listadoEstados)) {
    const matchLectura = listadoEstados
      .slice()
      .reverse()
      .find(item => item.estado === TipoEtapaTrackingEnum.LECTURA_EXITOSA || item.estado === TipoEtapaTrackingEnum.LECTURA_NO_EXITOSA);
    if (matchLectura) {
      if (matchLectura.estado === TipoEtapaTrackingEnum.LECTURA_EXITOSA) color = TipoColoresEnum.VERDE_OSCURO;
      if (matchLectura.estado === TipoEtapaTrackingEnum.LECTURA_NO_EXITOSA) color = TipoColoresEnum.AMARILLO;
    }
  }
  return color;
};

/**
 * Función encargada de retornar el color de la etapa
 * @param estado estados de tracking
 * @returns retorna el color
 */
export const obtenerEstadoEntregaBoleta = (listadoEstados: any[]): TipoColoresEnum => {
  let color: TipoColoresEnum = TipoColoresEnum.GRIS_L63;
  if (esDatoValido(listadoEstados)) {
    const matchLectura = listadoEstados
      .slice()
      .reverse()
      .find(item => item.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA || item.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA);
    if (matchLectura) {
      if (matchLectura.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA) color = TipoColoresEnum.VERDE_OSCURO;
      if (matchLectura.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA) color = TipoColoresEnum.AMARILLO;
    }
  }
  return color;
};

/**
 * Obtiene la fecha solo si hace match con el estado BOLETA_ENTREGADA
 * @param respuestaServicio respuesta de tracking CC
 * @returns valor de la fecha | string vacio
 */
export const obtenerFechaTrackingCC = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.datos?.fechaRealTomaLectura &&
  obtenerEstadoTomaLectura(respuestaServicio.estadosAnteriores) === TipoColoresEnum.VERDE_OSCURO &&
  formatoFecha(respuestaServicio.datos.fechaRealTomaLectura);

/**
 * Obtiene el icono por etapa de estados anteriores
 * @param respuestaServicio respuesta de tracking CC
 * @returns icono
 */
export const obtenerIconoTomaLecturaTrackingCC = (respuestaServicio: TrackingEstadosPorIdResponseModel) => {
  if (obtenerEstadoTomaLectura(respuestaServicio.estadosAnteriores) === TipoColoresEnum.VERDE_OSCURO) {
    return CheckTrackingIcon;
  }
  if (obtenerEstadoTomaLectura(respuestaServicio.estadosAnteriores) === TipoColoresEnum.AMARILLO) {
    return AdvertenciaIcon;
  }
  if (obtenerEstadoTomaLectura(respuestaServicio.estadosAnteriores) === TipoColoresEnum.GRIS_L63) {
    return RelojIcon;
  }
  return RelojIcon;
};

/**
 * Obtiene true si hace match con el estado LECTURA_NO_EXITOSA y false para otros estados
 * @param respuestaServicio respuesta de tracking CC
 * @returns booleano
 */
export const obtenerPrimerEstadoMostrarTooltipTrackingCC = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  obtenerEstadoTomaLectura(respuestaServicio.estadosAnteriores) === TipoColoresEnum.AMARILLO;

/**
 * Obtiene true si hace match con el estado BOLETA_NO_ENTREGADA y false para otros estados
 * @param respuestaServicio respuesta de tracking CC
 * @returns booleano
 */
export const obtenerTercerTextoMostrarTooltipTrackingCC = (respuestaServicio: TrackingEstadosPorIdResponseModel, t: any) =>
  obtenerEstadoEntregaBoleta(respuestaServicio.estadosAnteriores) === TipoColoresEnum.AMARILLO &&
  t('tracking:paginas.tracking.cicloFacturacion.boletaNoEntregada.mensajesEtapa.tooltip');

/**
 * Obtiene el icono por etapa de estados anteriores
 * @param respuestaServicio respuesta de tracking CC
 * @returns booleano
 */
export const obtenerIconoEntregaBoletaTrackingCC = (respuestaServicio: TrackingEstadosPorIdResponseModel) => {
  if (obtenerEstadoEntregaBoleta(respuestaServicio.estadosAnteriores) === TipoColoresEnum.VERDE_OSCURO) {
    return CheckTrackingIcon;
  }
  if (obtenerEstadoEntregaBoleta(respuestaServicio.estadosAnteriores) === TipoColoresEnum.AMARILLO) {
    return AdvertenciaIcon;
  }
  if (obtenerEstadoEntregaBoleta(respuestaServicio.estadosAnteriores) === TipoColoresEnum.GRIS_L63) {
    return RelojIcon;
  }
  return RelojIcon;
};

/**
 * Obtiene true si hace match con el estado BOLETA_NO_ENTREGADA y false para otros estados
 * @param respuestaServicio respuesta de tracking CC
 * @returns booleano
 */
export const obtenerTercerEstadoMostrarTooltipTrackingCC = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  obtenerEstadoEntregaBoleta(respuestaServicio.estadosAnteriores) === TipoColoresEnum.AMARILLO;

/**
 * Obtiene la fecha solo si hace match con el estado BOLETA_ENTREGADA
 * @param respuestaServicio respuesta de tracking CC
 * @returns valor de la fecha | string vacio
 */
export const obtenerFechaEntregaBoletaTrackingCC = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.datos?.fechaEntregaBoleta &&
  obtenerEstadoEntregaBoleta(respuestaServicio.estadosAnteriores) === TipoColoresEnum.VERDE_OSCURO &&
  formatoFecha(respuestaServicio.datos.fechaEntregaBoleta);

/**
 * Obtiene la validación de la boleta pagada
 * @param respuestaServicio respuesta servicio estado por id
 * @param facturacion
 * @returns retorna verdadero o falso
 */
export const obtenerFlagPagada = (estadoCiclo: TipoEtapaTrackingEnum, facturacion: FacturacionResponseModel) =>
  facturacion.cobroActual === null &&
  (estadoCiclo === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA ||
    estadoCiclo === TipoEtapaTrackingEnum.BOLETA_ENTREGADA ||
    estadoCiclo === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA);

/**
 * Obtiene el flag para el condicionante de boleta por vencer
 * @param estadoCiclo Estado actual
 * @param responseFacturacion Respuesta servicio facturacion
 * @returns retorna verdadero o falso
 */
export const obtenerFlagPorVencer = (estadoCiclo: TipoEtapaTrackingEnum, fechaVencimiento: string | null): boolean => {
  const fechaVencimientoFacturacion = endOfDay(addDays(new Date(esDatoValido(fechaVencimiento) && fechaVencimiento), 1));
  /**
   * Fecha de hoy
   */
  const fechaRangoInicio = endOfDay(new Date());
  /**
   * Fecha de hoy
   */
  const fechaRangoFin = endOfDay(addDays(fechaRangoInicio, 5));
  if (!esDatoValido(fechaVencimiento)) {
    return false;
  }
  return (
    esDatoValido(fechaVencimiento) &&
    fechaRangoInicio.getTime() <= fechaVencimientoFacturacion.getTime() &&
    fechaVencimientoFacturacion.getTime() <= fechaRangoFin.getTime() &&
    (estadoCiclo === TipoEtapaTrackingEnum.BOLETA_ENTREGADA || estadoCiclo === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA)
  );
};

/**
 * Obtiene el flag para el condicionante de boleta vencida
 * @param estadoCiclo Estado actual
 * @param responseFacturacion Respuesta servicio facturacion
 * @returns retorna verdadero o falso
 */
export const obtenerFlagVencido = (
  estadoCiclo: TipoEtapaTrackingEnum,
  responseFacturacion: FacturacionResponseModel,
  fechaVencimiento: string | null
) =>
  esDatoValido(fechaVencimiento) &&
  responseFacturacion.cobroActual?.estadoPago === TipoPagoBoleta.VENCIDO &&
  (estadoCiclo === TipoEtapaTrackingEnum.BOLETA_ENTREGADA || estadoCiclo === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA);
/**
 * Obtiene flag enviada por email si es digital o analogo
 * @param respuestaServicio respuesta servicio estado por id
 * @returns retorna verdadero o falso
 */
export const obtenerFlagEnviadaPorEmail = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.datos.enviada_por_email === TipoEnviadaPorEmail.DIGITAL;

/**
 * Obtiene flag indicador de ahorro
 * @param respuestaServicio respuesta servicio estado por id
 * @returns retorna verdadero o falso
 */
export const obtenerFlagIndicarAhorro = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.datos.indicadorAhorro === 'true';

/**
 * Obtener fecha de emision cobro actual
 * @param facturacion respuesta servicio facturacion
 * @returns string fecha
 */
export const obtenerFechaEmisionCobroActual = (facturacion: FacturacionResponseModel) =>
  facturacion.cobroActual?.fechaEmisionBoleta && formatoFecha(facturacion.cobroActual?.fechaEmisionBoleta);

/**
 * Obtener fecha de emision datos anexos
 * @param respuestaServicio respuesta servicio estado por id
 * @returns string fecha
 */
export const obtenerFechaEmisionDatosAnexos = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.datos?.fechaEntregaBoleta && formatoFecha(respuestaServicio.datos.fechaEntregaBoleta);

/**
 * Obtiene la segunda y tercera condicion stepper
 * @param respuestaServicio respuesta servicio estado por id
 * @returns retorna verdadero o falso
 */
export const obtenerSegundaTerceraCondicionStepper = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.estado === TipoEtapaTrackingEnum.EN_TOMA_LECTURA ||
  respuestaServicio.estado === TipoEtapaTrackingEnum.LECTURA_EXITOSA ||
  respuestaServicio.estado === TipoEtapaTrackingEnum.LECTURA_NO_EXITOSA;

/**
 * Obtiene la segunda y tercera condicion stepper
 * @param titulo titulo etapa
 * @returns modelo etapa
 */
export const initModelosegundoTercerUltimoStepper = (titulo: string) => ({
  tamanioLinea: TipoLargoLineaEnum.FULL,
  colorPaso: TipoColoresEnum.GRIS_L93,
  colorTitulo: TipoColoresEnum.GRIS_L93,
  colorFecha: TipoColoresEnum.GRIS_L93,
  colorLinea: TipoColoresEnum.GRIS_L93,
  titulo,
  fecha: '',
  icono: RelojIcon,
  mostrarTooltip: false,
  textoTooltip: '',
});

/**
 * Obtiene la fecha de vencimiento modelo cobro actual
 * @param facturacion respuesta servicio facturacion
 * @returns string fecha vencimiento
 */
export const obtenerFechaVenciminetoCobroActual = (facturacion: FacturacionResponseModel) =>
  facturacion.cobroActual?.fechaVencimiento && formatoFecha(facturacion.cobroActual.fechaVencimiento);

/**
 * Obtener fecha entrega
 * @param respuestaServicio respuesta servicio estado por id
 * @returns string fecha entrega boleta
 */
export const obtenerFechaEntregaBoleta = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.datos.fechaEntregaBoleta && formatoFecha(respuestaServicio.datos.fechaEntregaBoleta);

/**
 * Obtener fecha inicio lectura
 * @param respuestaServicio respuesta servicio estado por id
 * @returns string fecha inicio lectura
 */
export const obtenerFechaIncioLectura = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.datos.fechaInicioLectura && formatoFecha(respuestaServicio.datos?.fechaInicioLectura);

/**
 * Obtener fecha fin lectura
 * @param respuestaServicio respuesta servicio estado por id
 * @returns string fecha fin lectura
 */
export const obtenerFechaFinLectura = (respuestaServicio: TrackingEstadosPorIdResponseModel) =>
  respuestaServicio.datos.fechaFinLectura && formatoFecha(respuestaServicio.datos?.fechaFinLectura);

/**
 * Obtener el Flag de boleta entrgada y no entregada
 * @param respuestaServicio respuesta servicio estado por id
 * @param facturacion respuesta servicio facturacion
 * @param fechaVencimiento
 * @returns retorna verdadero o falso
 */
export const obtenerFlagBoletaEntregada = (
  respuestaServicio: TrackingEstadosPorIdResponseModel,
  facturacion: FacturacionResponseModel,
  fechaVencimiento: string | null
) =>
  (respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA ||
    respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA ||
    obtenerFlagPorVencer(respuestaServicio.estado, fechaVencimiento)) &&
  !obtenerFlagPagada(respuestaServicio.estado, facturacion);

/**
 * Obtener el Flag desde boleta emitida
 * @param respuestaServicio respuesta servicio estado por id
 * @param facturacion respuesta servicio facturacion
 * @param fechaVencimiento fecha de vencimiento
 * @returns retorna verdadero o falso
 */
export const obtenerFlagDesdeBoletaEmitida = (
  respuestaServicio: TrackingEstadosPorIdResponseModel,
  facturacion: FacturacionResponseModel,
  fechaVencimiento: string | null
) =>
  respuestaServicio.estado === TipoEtapaTrackingEnum.NOTIFICACION_BOLETA ||
  respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_ENTREGADA ||
  respuestaServicio.estado === TipoEtapaTrackingEnum.BOLETA_NO_ENTREGADA ||
  obtenerFlagPagada(respuestaServicio.estado, facturacion) ||
  obtenerFlagVencido(respuestaServicio.estado, facturacion, fechaVencimiento);
